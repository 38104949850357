import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { HttpService } from '../http/services/http.service';
import {
  ApiEndpoint,
  ApiMethod,
  IApiResponse,
  IPagingResponse,
} from '../http/api';
import { StorageService } from '../storage/storage.service';
import { StorageKey, StorageType } from '../storage/storage';
import { AuthUtils } from './auth.utils';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _authenticated: boolean = false;

  constructor(
    private _userService: UserService,
    private _httpService: HttpService,
    private _storageService: StorageService,
    private _router: Router
  ) {}

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    this._storageService.setItem(
      StorageType.Local,
      StorageKey.AccessToken,
      token
    );
  }

  get accessToken(): string {
    return (
      this._storageService.getItem(StorageType.Local, StorageKey.AccessToken) ??
      ''
    );
  }

  /**
   * Setter & getter for refresh token
   */
  set refreshToken(token: string) {
    this._storageService.setItem(
      StorageType.Local,
      StorageKey.RefreshToken,
      token
    );
  }

  get refreshToken(): string {
    return (
      this._storageService.getItem(
        StorageType.Local,
        StorageKey.RefreshToken
      ) ?? ''
    );
  }

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string) {}

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string) {}

  /**
   * Login
   *
   * @param credentials
   */
  login(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError(() => 'User is already logged in.');
    }

    return this._httpService
      .request({
        apiUrl: ApiEndpoint.Login,
        method: ApiMethod.Post,
        body: credentials,
      })
      .pipe(
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.accessToken;

          // Set the authenticated flag to true
          this._authenticated = true;

          this._userService.user = response;

          if (response.isSuperAdmin) {
            // Return a new observable with the response
            return of(response);
          }

          return of(response);
        })
      );
  }

  /**
   * Logout
   */
  logout(): Observable<any> {
    // Remove the access token from the local storage
    this._storageService.removeItem(StorageType.Local, StorageKey.AccessToken);
    // Remove the refresh token from the local storage
    this._storageService.removeItem(StorageType.Local, StorageKey.RefreshToken);
    // Remove the language from the local storage
    this._storageService.removeItem(StorageType.Local, StorageKey.Language);

    // Set the authenticated flag to false
    this._authenticated = false;

    this._userService.user = null;

    // Navigate to login page
    this._router.navigateByUrl('login');

    // Return the observable
    return of(true);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (
      AuthUtils.isTokenExpired(this.accessToken) ||
      !this._userService.userValue
    ) {
      // Get the new access token
      return this.getAccessToken();
    }

    // If the access token exists and it didn't expire, sign in using it
    return of(true);
  }

  getAccessToken(): Observable<any> {
    return this._httpService
      .request({
        apiUrl: ApiEndpoint.RefreshToken,
        method: ApiMethod.Post,
        body: {
          accessToken: this.accessToken,
          refreshToken: this.refreshToken,
        },
      })
      .pipe(
        switchMap((res: IApiResponse) => {
          const result = res.result;
          // Update the access token and refresh token
          this.accessToken = result.accessToken;
          this.refreshToken = result.refreshToken;

          this._userService.user = result;

          // if (result.isSuperAdmin) {
          //   return of(true);
          // }

          return of(true);
        }),
        catchError((err) => {
          // Logout
          this.logout();

          return of(false);
        })
      );
  }

  getToken(payload: { userName: string; password: string }): Observable<any> {
    return this._httpService
      .request({
        apiUrl: ApiEndpoint.Authenticate,
        method: ApiMethod.Post,
        body: payload,
      })
      .pipe(
        switchMap((res: any) => {
          // Set authenticated flag to true
          this._authenticated = true;

          // Get the result data
          const result = res.result ?? {};

          // Store access token in the local storage
          this.accessToken = result.accessToken;

          // Store access token in the local storage
          this.refreshToken = result.refreshToken;

          return of(res);
        })
      );
  }

  getUserRole(id: string) {
    const params = {
      filterOptions: {
        pageIndex: 1,
        pageSize: 999,
        sorter: {
          modifiedDate: 'desc',
        },
      },
      search: '',
    };
    this._userService.resources = [];
    return of([]);
  }

  getUserPermission(roleId: string) {
    const params = {
      filterOptions: {
        pageIndex: 1,
        pageSize: 999,
        sorter: {
          modifiedDate: 'desc',
        },
      },
      search: '',
    };
    return this._httpService
      .request({
        apiUrl: ApiEndpoint.GetRolePermission,
        method: ApiMethod.Post,
        body: params,
      })
      .pipe(
        map((res: IPagingResponse<any>) => {
          const result =
            res.result?.data?.filter((item) => item.roleId == roleId) || [];
          this._userService.permissions = result;
          return result;
        })
      );
  }
}
